import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import type { ADDoc } from '@atlaskit/editor-common/validator';

import type { TemplatePreviewTemplate } from '../../templatePreviewHelpers';

import { getMacroExtensionHandler } from './macroExtensionHandler';
import { getTemplateExtensionHandler } from './templateExtensionHandler';

export type GetExtensionHandlersArgs = {
	adf: ADDoc;
	cloudId: string;
	userId: string | null;
	contentId?: string;
	spaceKey?: string;
	template?: TemplatePreviewTemplate;
};
export const getExtensionHandlers = (
	extensionHandlerArgs: GetExtensionHandlersArgs,
): ExtensionHandlers => {
	return {
		'com.atlassian.confluence.macro.core': getMacroExtensionHandler(extensionHandlerArgs),
		'com.atlassian.confluence.template': getTemplateExtensionHandler(extensionHandlerArgs),
	};
};
